import { CircularProgress } from "@mui/material"

const Spinner = ({isActive}) => {
	if (isActive) {
		return <CircularProgress />;
	}
	return null;
}

export default Spinner;
