import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import axios from "axios";

const RebuildButton = ({ onRebuild, loading }) => (
	<Button
		onClick={onRebuild}
		disabled={loading}
		variant="contained"
		sx={{
			padding: "1rem 2rem",
			borderRadius: "30px",
			fontWeight: "bold",
			background: "linear-gradient(145deg, #0072ff, #00c6ff)",
			color: "#ffffff",
			boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
			"&:hover": {
				background: "linear-gradient(145deg, #005cb2, #0088cc)",
				boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.2)",
			},
			transition: "all 0.3s ease",
		}}
	>
		{loading ? "Please Wait..." : "Index Out of Date. Rebuild Now"}
		<Send sx={{ ml: 1 }} />
	</Button>
);

// main component for checking whether or not to display button
const CheckIndexDate = () => {
    // initialize the buttons to false
    const [showButton, setShowButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    // getting data from the backend
    useEffect(() => {
        const authToken = localStorage.getItem("authToken");
        const checkModifiedDate  = async () => {
            try {
                const response = await axios.post(
                  "https://chat-api.edukona.com/api/check-date/",
                  null,
                  {
                    headers: { 
                      "Content-Type": "application/json",
                      "Authorization": `Token ${authToken}` },
                  }
                );
                const { status_code } = response.data;
                setShowButton(() => status_code === "rebuild");
            } catch (error) {
              console.error("Error checking date:", error);
              setShowButton(false);
            }
        };
        // run the component once when entering page
        checkModifiedDate();
    }, []);

    const startPolling = () => {
      if (isPolling) return;
      
      const authToken = localStorage.getItem("authToken");
      console.log("Starting polling...");
      setIsPolling(true);
  
      const intervalId = setInterval(async () => {
        try {
          console.log("Polling index status...");
          const response = await axios.get("https://chat-api.edukona.com/api/rebuild/", {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Token ${authToken}`,
            },
          });
          const { server_response } = response.data;
          console.log(response.data);
          if (server_response === "finished") {
            console.log("Rebuild complete");
            clearInterval(intervalId);
            setShowButton(false);
            setIsPolling(false);
          } else if (server_response === "pending") {
            console.log("Still rebuilding...");
          } else {
            console.log("Unknown response, continuing polling...");
          }
        } catch (error) {
          console.error("Error getting index status:", error);
        }
      }, 10000);
  
      return () => clearInterval(intervalId);
    };


    const rebuildIndex = async () => {
        setLoading(true);
        try {
            const authToken = localStorage.getItem("authToken");
            await axios.post(
                "https://chat-api.edukona.com/api/rebuild/",
                null,
                {
                  headers: { 
                    "Content-Type": "application/json",
                    "Authorization": `Token ${authToken}` },
                }
              );
              startPolling();
        } catch (error) {
            console.error("Error rebuilding index:", error);
          } finally {
            setLoading(false);
          }      
    };

return (
    <div>
        {showButton && <RebuildButton onRebuild={rebuildIndex} loading={loading} />}
    </div>
    );
};
export default CheckIndexDate;