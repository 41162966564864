import { Send } from "@mui/icons-material";
import { Card, Fab, Typography } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Box } from "@mui/system";
import axios from "axios";
import { useState } from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Spinner from "./Spinner";

const Chat = () => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState("");
  const [urlResponse, setUrlResponse] = useState([]);
  const [errorOutput, setErrorOutput] = useState("");

  const handleQuery = () => {
    const authToken = localStorage.getItem("authToken");
    setLoading(true);
    axios
      .post(`https://5hp3l5xhgc.execute-api.us-west-2.amazonaws.com/Prod/process-query`, { input: query }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Token ${authToken}`
        },
      })
      .then((res) => {
        const rawMarkdownResponse = res.data.llm_response.response;
        const urlResponse = res.data.llm_response.sources;
        console.log(rawMarkdownResponse);
        console.log(urlResponse);
        setOutput(rawMarkdownResponse);
        setUrlResponse(urlResponse);
        setErrorOutput("");
      })
      .catch((error) => {
        setOutput(`Sorry, we couldn't process your query.`);
        setUrlResponse([]);
        setErrorOutput(`${error}`);
      })
      .finally(() => setLoading(false));
    console.log(query);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleQuery();
    }  
  };

  return (<Box sx={styles.container}>
    <Box sx={styles.outputContainer}>
      {loading && <Spinner isActive={loading}/>}
      {!loading && output && (
          <Card elevation={3} sx={styles.card}>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              children={output}
            />
            {urlResponse.length > 0 && (
              <>
                <Typography variant="h6" sx={styles.sourceTitle}>
                  Sources:
                </Typography>
                <ul>
                {urlResponse.map((url) => (
                  <li key={url}>
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url}
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Card>
      )}
      {errorOutput && (<Typography variant="body1" color="error" sx={{ textAlign: "left" }}>
        {errorOutput}
      </Typography>)}
    </Box>
    <Box sx={styles.inputContainer}>
      <TextareaAutosize
        minRows={4}
        maxRows={10}
        placeholder="Ask the chatbot a question"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        style={styles.textArea}
      />
      <Fab color="primary" onClick={handleQuery}>
        <Send/>
      </Fab>
    </Box>
  </Box>);
};

const styles = {
  container: {
    width: "100%", maxWidth: "800px", margin: "auto", padding: "2rem", display: "flex", flexDirection: "column", gap: "1rem",
  }, outputContainer: {
    flex: 1, display: "flex", flexDirection: "column", alignItems: "center",
  }, card: {
    width: "100%", padding: "1rem",
  }, outputText: {
    whiteSpace: "pre-wrap", textAlign: "justify",
  }, inputContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "flex-start",
  },
  textArea: {
    width: "100%",
    fontSize: "16px",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    resize: "none",
  },
};

export default Chat;
