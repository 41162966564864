import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { Container, Card, CardContent, Typography, Box } from "@mui/material";

const LoginPage = ({ setIsLoggedIn }) => {
  const handleSuccess = (credentialResponse) => {
    const googleToken = credentialResponse.credential;

    fetch("https://chat-api.edukona.com/api/auth/google/", {
      method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ token: googleToken }),
    })
      .then((res) => {
        if (!res.ok) {
          if (res.status === 400) {
            alert("Please check email domain type");
          } else if (res.status === 500) {
            alert("Internal Server Error: Please contact a System Administrator");
          } else {
            alert(`Unexpected error: ${res.statusText}`)
          }
          throw new Error("Failed to login");
        }
        return res.json();
      })
      .then((data) => {
        localStorage.setItem("authToken", data.token);
        setIsLoggedIn(true);
      })
      .catch((err) => console.error("Google login error:", err));
  };

  const handleError = () => {
    console.log("Google login failed");
  };

  return (<Box
    sx={{
      minHeight: "100vh", background: "linear-gradient(140deg, #fafafa 20%, #e7f0ff 100%)", display: "flex", alignItems: "center", justifyContent: "center",
    }}
  >
    <Container maxWidth="sm">
      <Card
        sx={{
          padding: "2rem", boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)", borderRadius: "12px",
        }}
      >
        <CardContent
          sx={{
            display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem",
          }}
        >
          <Typography variant="h4" component="h1" sx={{ fontWeight: "bold", mb: 2 }}>
            Welcome
          </Typography>
          <Typography variant="body1" sx={{ mb: 3, textAlign: "center" }}>
            Please sign in with Google to continue.
          </Typography>
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleError}
            useOneTap
            shape="pill"
            size="large"
            text="continue_with"
          />
        </CardContent>
      </Card>
    </Container>
  </Box>);
};

export default LoginPage;
