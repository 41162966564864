import React from "react";
import { AppBar, Typography, Button, Toolbar } from "@mui/material";

function NavBar({ isLoggedIn, handleLogout }) {
  return (<AppBar position="static" sx={{
    background: "linear-gradient(145deg, #0a58ca, #1058c0)", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
  }}>
    <Toolbar
      sx={{
        display: "flex", justifyContent: "space-between",
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ textAlign: "left", fontWeight: "bold" }}
      >
        UNCC Chatbot
      </Typography>

      {isLoggedIn && (<Button size={"large"} color="inherit" onClick={handleLogout}>
        Logout
      </Button>)}
    </Toolbar>
  </AppBar>);
}

export default NavBar;
