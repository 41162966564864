import React from "react";
import { Box } from "@mui/system";
import NavBar from "../components/NavBar";
import CheckIndexDate from "../components/CheckIndexDate";
import Chat from "../components/Chat";

const ChatPage = ({ isLoggedIn, handleLogout }) => {
  return (<Box
    sx={{
      backgroundColor: "#ffffff", minHeight: "100vh", display: "flex", flexDirection: "column",
    }}
  >
    <NavBar isLoggedIn={isLoggedIn} handleLogout={handleLogout}/>
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2rem",
        width: "80%",
        margin: "auto",
        gap: "2rem",
      }}
    >
      <CheckIndexDate/>
    </Box>
    <Box
      sx={{
        padding: "2rem", backgroundColor: "#fff", width: "100%", display: "flex", justifyContent: "center", position: "sticky", bottom: 0,
      }}
    >
      <Chat/>
    </Box>
  </Box>);
};

export default ChatPage;
