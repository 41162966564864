import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router, Routes, Route, Navigate,
} from "react-router-dom";
import ChatPage from './pages/ChatPage';
import LoginPage from "./pages/LoginPage";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setIsLoggedIn(false);
      setLoading(false);
    } else {
      // If a token is present, we assume they're logged in
      // Optionally validate the token here or in an effect
      setIsLoggedIn(true);
      setLoading(false);
    }
  }, []);

  const handleLogout = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setIsLoggedIn(false);
      return;
    }

    try {
      const response = await fetch("https://chat-api.edukona.com/api/logout/", {
        method: "POST", headers: {
          "Content-Type": "application/json", Authorization: `Token ${token}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem("authToken");
        setIsLoggedIn(false);
      } else {
        console.error("Logout failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (<Router>
    <Routes>
      <Route
        path="/"
        element={isLoggedIn ? (// Pass `handleLogout` and `isLoggedIn` to ChatPage
          <ChatPage isLoggedIn={isLoggedIn} handleLogout={handleLogout}/>) : (<Navigate to="/login"/>)}
      />
      <Route
        path="/login"
        element={isLoggedIn ? <Navigate to="/"/> : <LoginPage setIsLoggedIn={setIsLoggedIn}/>}
      />
    </Routes>
  </Router>);
}

export default App;
